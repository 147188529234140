import { PAGE_URL } from '../../../config/appConfig';

export const getNavData = (t) => {
  return [
    {
      label: t('app.SERVICE'),
      children: [
        { label: t('app.B2C'), href: PAGE_URL.SERVICES_B2C },
        { label: t('app.B2B'), href: PAGE_URL.SERVICES_B2B },
        {
          label: t('app.CROSS_BORDER'),
          href: PAGE_URL.SERVICES_CBL,
        },
        {
          label: t('app.3PL'),
          href: PAGE_URL.SERVICES_TPL,
        },
      ],
    },
    { label: t('app.ABOUT_US'), href: PAGE_URL.ABOUT_US },
    { label: t('app.CAREERS'), href: PAGE_URL.CAREER_PAGE },
    { label: t('app.CONTACT_US'), href: PAGE_URL.CONTACT_US },
    {
      label: t('app.MEDIA'),
      children: [
        { id: 1, label: t('app.HEADER_BLOG'), href: PAGE_URL.MEDIA_BLOGS },
        { id: 2, label: t('app.HEADER_NEWS'), href: PAGE_URL.NEWSPAGE_ALL },
      ],
    },
    {
      label: t('app.HEADER_LOGIN'),
      children: [
        {
          id: 1,
          label: t('app.DOMESTIC'),
          href: 'https://xbees.in/redirect.aspx',
          target: '_blank',
        },
        {
          id: 2,
          label: t('app.CROSS_BORDER'),
          href: 'https://clientportal.xbees.in/login',
          target: '_blank',
        },
      ],
    },
    {
      label: t('app.HEADER_TRACKSHIPMENT'),
      href: PAGE_URL.SHIPMENT_TRACKING,
      className: 'track-shipment',
    },
  ];
};
